import React, { useState } from "react";
import {
  MDBBtn,
  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBCardImage,
  MDBRow,
  MDBCol,
  MDBIcon,
  MDBInput,
} from "mdb-react-ui-kit";
import "./SignIn.css";
import logo from "./Images/Logo.jpg";
import "./App.css";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import config from "./config";
import Cookies from "js-cookie";

function SignIn({ setIsAuthenticated }) {
  const [formData, setFormData] = useState({ email: '', password: '' });
  const [errors, setErrors] = useState({});
  const [apiError, setApiError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setApiError('');
      setIsLoading(true);
      try {
        const response = await axios.post(`${config.apiBaseUrl}/login`, formData);
        setIsAuthenticated(true);
        Cookies.set("authenticationData", JSON.stringify(response.data), { expires: 1 }); // Store cookie for 1 days
        setIsLoading(false);
        navigate('/reservation');
      } catch (error) {
        setApiError('Invalid email or password. Please try again.');
        setIsLoading(false);
      }
    } else {
      console.log("Form has errors");
    }
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.email.trim()) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Email is invalid";
    }

    if (!formData.password.trim()) {
      newErrors.password = "Password is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  return (
    <MDBContainer className="my-5">
      <MDBCard>
        <MDBRow className="g-0">
          <MDBCol md="6">
            <MDBCardImage
              src={logo}
              alt="login form"
              className="rounded-start w-100"
            />
          </MDBCol>

          <MDBCol md="6">
            <MDBCardBody className="d-flex flex-column">
              <div className="d-flex flex-row mt-2">
                <MDBIcon
                  fas
                  icon="cubes fa-3x me-3"
                  style={{ color: "#ff6219" }}
                />
                <span className="h1 fw-bold mb-0">Sign in</span>
              </div>

              <h5
                className="fw-normal my-4 pb-3"
                style={{ letterSpacing: "1px", color: "black" }}
              >
                Login into your account
              </h5>
              {apiError && <p className="text-danger">{apiError}</p>}

              <MDBInput
                wrapperClass="mb-4"
                label="Email address"
                id="email"
                type="email"
                size="lg"
                value={formData.email}
                onChange={handleChange}
              />
              {errors.email && (
                <p className="text-danger">{errors.email}</p>
              )}
              <MDBInput
                wrapperClass="mb-4"
                label="Password"
                id="password"
                type="password"
                size="lg"
                value={formData.password}
                onChange={handleChange}
              />
              {errors.password && (
                <p className="text-danger">{errors.password}</p>
              )}
              <MDBBtn className="mb-4 px-5" color="dark" size="lg" onClick={handleSubmit} disabled={isLoading}>
                {isLoading ? 'Loading...' : 'Sign In'}
              </MDBBtn>
            </MDBCardBody>
          </MDBCol>
        </MDBRow>
      </MDBCard>
    </MDBContainer>
  );
}

export default SignIn;