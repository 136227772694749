import React, { useState } from "react";
import {
  MDBBtn,
  MDBContainer,
  MDBCard,
  MDBCardBody,
  MDBInput,
  MDBRow,
  MDBCol,
} from "mdb-react-ui-kit";
import logoSignup from "./Images/logo_signup.jpg";
import "./SignUp.css"; // Importer le fichier CSS
import "./App.css";
import config from "./config";
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import Cookies from "js-cookie";

function SignUp({setIsAuthenticated}) {
  // Gestion des états pour chaque champ
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    zeffyConfirmation: "",
    postalCode: "",
    gender: "Female",
  });

  const [errors, setErrors] = useState({});

  const [apiError, setApiError] = useState('');

  const [successMessage, setSuccessMessage] = useState('');

  const [isLoading, setIsLoading] = useState(false);


  const navigate = useNavigate();

  // Fonction de gestion des changements de formulaire
  const handleInputChange = (e) => {
    const { id, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [id]: id === "zeffyConfirmation" ? parseInt(value, 10) : value,
    });
  };

  // Fonction de validation des champs
  const validateForm = () => {
    const newErrors = {};

    if (!formData.firstName.trim()) {
      newErrors.firstName = "First name is required";
    }

    if (!formData.lastName.trim()) {
      newErrors.lastName = "Last name is required";
    }

    if (!formData.email.trim()) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Email is invalid";
    }

    if (!formData.password.trim()) {
      newErrors.password = "Password is required";
    } else if (formData.password.length < 6) {
      newErrors.password = "Password must be at least 6 characters long";
    }

    if (!formData.confirmPassword.trim()) {
      newErrors.confirmPassword = "Confirm Password is required";
    } else if (formData.password !== formData.confirmPassword) {
      newErrors.confirmPassword = "Passwords do not match";
    }

    if (!formData.zeffyConfirmation) {
      newErrors.zeffyConfirmation = "Code Confirmation Zeffy is required";
    }

    if (!formData.postalCode.trim()) {
      newErrors.postalCode = "ZIP Code is required";
    } else if (!/^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/.test(formData.postalCode)) {
      newErrors.postalCode = "ZIP Code is invalid";
    }

    if (!formData.gender.trim()) {
      newErrors.gender = "Please select your gender";
    }

    setErrors(newErrors);

    // Si l'objet des erreurs est vide, cela signifie qu'il n'y a pas d'erreurs
    return Object.keys(newErrors).length === 0;
  };

  // Fonction de soumission du formulaire
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      setApiError('');
      setSuccessMessage('');
      setIsLoading(true);
      try {
        const responseSignUp = await axios.post(`${config.apiBaseUrl}/signup`, formData);

        setSuccessMessage('Registration successful.');

        const responseLogin = await axios.post(`${config.apiBaseUrl}/login`, {
          email: formData.email,
          password: formData.password
        });

        setIsAuthenticated(true);
        Cookies.set("authenticationData", JSON.stringify(responseLogin.data));
        setIsLoading(false);
        setFormData({
          firstName: '',
          lastName: '',
          email: '',
          password: '',
          confirmPassword: '',
          zeffyConfirmation: '',
          postalCode: '',
          gender: 'Female'
        });
            // Redirect to sign-in page after 5 seconds
          navigate('/reservation');
      } catch (error) {
        setApiError(`${error.response?.status} - ${error.response?.data}` || 'An error occurred. Please try again.');
        setIsLoading(false);
      }
    } else {
      console.log("Form has errors");
    }
  };

  return (
    <MDBContainer
      fluid
      className="d-flex justify-content-center align-items-center container-custom"
    >
      <MDBRow className="g-0 align-items-center row-custom">
        <MDBCol
          md="6"
          className="d-flex justify-content-center col-form-custom"
        >
          <MDBCard className="my-5 cascading-right card-custom">
            <MDBCardBody
              className="p-5 shadow-5 text-center card-body-custom"
              onSubmit={handleSubmit}
            >
              <h2 className="fw-bold mb-5">Register</h2>
              {apiError && <p className="text-danger">{apiError}</p>}
              {successMessage && <p className="text-success">{successMessage}</p>}
              <MDBRow>
                <MDBCol md="6">
                  <MDBInput
                    wrapperClass="mb-2"
                    label="First name"
                    id="firstName"
                    type="text"
                    value={formData.firstName}
                    onChange={handleInputChange}
                    className={errors.firstName ? "is-invalid" : ""}
                  />
                  {errors.firstName && (
                    <p className="text-danger">{errors.firstName}</p>
                  )}
                </MDBCol>

                <MDBCol md="6">
                  <MDBInput
                    wrapperClass="mb-2"
                    label="Last name"
                    id="lastName"
                    type="text"
                    value={formData.lastName}
                    onChange={handleInputChange}
                    className={errors.lastName ? "is-invalid" : ""}
                  />
                  {errors.lastName && (
                    <p className="text-danger">{errors.lastName}</p>
                  )}
                </MDBCol>
              </MDBRow>

              <MDBInput
                wrapperClass="mb-2"
                label="Email"
                id="email"
                type="email"
                value={formData.email}
                onChange={handleInputChange}
                className={errors.email ? "is-invalid" : ""}
              />
              {errors.email && <p className="text-danger">{errors.email}</p>}

              <MDBInput
                wrapperClass="mb-2"
                label="Password"
                id="password"
                type="password"
                value={formData.password}
                onChange={handleInputChange}
                className={errors.password ? "is-invalid" : ""}
              />
              {errors.password && (
                <p className="text-danger">{errors.password}</p>
              )}

              <MDBInput
                wrapperClass="mb-2"
                label="Confirm Password"
                id="confirmPassword"
                type="password"
                value={formData.confirmPassword}
                onChange={handleInputChange}
                className={errors.confirmPassword ? "is-invalid" : ""}
              />
              {errors.confirmPassword && (
                <p className="text-danger">{errors.confirmPassword}</p>
              )}

              <MDBInput
                wrapperClass="mb-2"
                label="Zeffy Confirmation Code"
                id="zeffyConfirmation"
                type="number"
                value={formData.zeffyConfirmation}
                onChange={handleInputChange}
                className={errors.zeffyConfirmation ? "is-invalid" : ""}
              />
              {errors.zeffyConfirmation && (
                <p className="text-danger">{errors.zeffyConfirmation}</p>
              )}

              <MDBInput
                wrapperClass="mb-2"
                label="ZIP Code"
                id="postalCode"
                type="text"
                value={formData.postalCode}
                onChange={handleInputChange}
                className={errors.postalCode ? "is-invalid" : ""}
              />
              {errors.postalCode && (
                <p className="text-danger">{errors.postalCode}</p>
              )}

              <select
                id="gender"
                value={formData.gender}
                onChange={handleInputChange}
                className={`mb-2 form-select ${
                  errors.gender ? "is-invalid" : ""
                }`}
              >
                <option value="">Select gender</option>
                <option value="Female">Female</option>
                <option value="Male">Male</option>
              </select>
              {errors.gender && <p className="text-danger">{errors.gender}</p>}

              <MDBBtn
                className="w-100 mb-4"
                size="md"
                color="success"
                onClick={handleSubmit}
                disabled={isLoading}
              >
                {isLoading ? 'Loading...' : 'Sign Up'}
              </MDBBtn>
            </MDBCardBody>
          </MDBCard>
        </MDBCol>

        <MDBCol
          md="5" /* Augmente la colonne à 5/12 (environ 42%) */
          className="d-flex justify-content-center align-items-center p-0 col-image-custom"
        >
          <img
            src={logoSignup}
            className="w-100 h-100 rounded-4 shadow-4 img-custom"
            alt="Sign Up Logo"
          />
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
}

export default SignUp;
