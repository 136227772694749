import React, { useState, useEffect } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import config from "./config";
import "./Reservation.css";
import "./App.css";

function Reservation() {
  const [selectedRoom, setSelectedRoom] = useState(null); // État pour la chambre sélectionnée
  const [rooms, setRooms] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [popup, setPopup] = useState({ visible: false, message: "" }); // State for popup
  const [reserveResponse, setReserveResponse] = useState(null); // State for reserve response

  const fetchRooms = async () => {
    try {
      const authData = JSON.parse(Cookies.get('authenticationData'));
      const token = authData?.idToken; // Assuming the token is stored in authData.token
      const gender = authData?.Gender; // Assuming the gender is stored in authData.Gender

      const response = await axios.get(`${config.apiBaseUrl}/rooms`, {
        params: { RoomGender: gender },
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      console.log(response.data);
      setRooms(response.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setError(`${error.response?.status} - ${error.response?.data} - Failed to load rooms.`);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRooms();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div className="text-danger">{error}</div>;
  }

  // Fonction qui gère la sélection d'une chambre
  const handleRoomClick = (roomId) => {
    setSelectedRoom(`room${roomId}`); // Met à jour la chambre sélectionnée avec la valeur "room1" au lieu de "1"
  };

  // Fonction qui gère la réservation d'une chambre
  const handleReserveRoom = async () => {
    if (!selectedRoom) {
      alert("Please select a room first.");
      return;
    }

    try {
      const authData = JSON.parse(Cookies.get('authenticationData'));
      const token = authData?.idToken; // Assuming the token is stored in authData.token
      const userId = authData?.UID; // Assuming the user ID is stored in authData.UserId

      const response = await axios.post(`${config.apiBaseUrl}/reserve`, { roomId: selectedRoom, userId: userId }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      console.log('Room reserved successfully', response.data);
      setPopup({ visible: true, message: response.data.message || 'Room reserved successfully!' });
      setReserveResponse(response.data); // Set the reserve response data

      // Update the cookie with the new reservation data
      const updatedAuthData = { ...authData, AssignedRoom: selectedRoom };
      Cookies.set('authenticationData', JSON.stringify(updatedAuthData));

      // Refresh room data 2000ms after reservation
      setTimeout(fetchRooms, 50);

    } catch (error) {
      console.error('Error reserving room', error, error.response?.data);
      const errorMessage = error.response?.data || 'Error reserving room. Please try again.';
      setPopup({ visible: true, message: errorMessage });
    }
  };

  const closePopup = () => {
    setPopup({ visible: false, message: "" });
  };

  return (
    <div className="container">
      <h1>Select Your Room</h1>
      <div className="grid">
        {Object.entries(rooms).map(([key, room], idx) => (
          <div
            key={room.RoomID}
            className={`box ${selectedRoom === `room${room.RoomID}` ? "selected" : ""}`}
            onClick={() => handleRoomClick(room.RoomID)}
          >
            Room {room.RoomID}
            <br />
            Occupants: {room.NumberOfOccupants} / {room.Capacity}
          </div>
        ))}
      </div>
      {selectedRoom && (
        <p className="selected-room">Selected Room: {selectedRoom}</p>
      )}
      <div className="reserve-button-container">
        <button onClick={handleReserveRoom}>Reserve this room</button>
      </div>
      {popup.visible && (
        <div className="popup">
          <div className="popup-content">
            <p>{popup.message}</p>
            <button onClick={closePopup}>Close</button>
          </div>
        </div>
      )}
      {reserveResponse && (
        <div className="reserve-response">
          <h2>Reservation Response</h2>
          <pre>{JSON.stringify(reserveResponse, null, 2)}</pre>
        </div>
      )}
    </div>
  );
}

export default Reservation;