import React, { useState, useEffect } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import config from "./config";
import "./Reservation.css";
import "./App.css";

function Reservation() {
  const [selectedRoom, setSelectedRoom] = useState(null); // État pour la chambre sélectionnée
  const [room, setRoom] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [popup, setPopup] = useState({ visible: false, message: "" }); // State for popup
  const [reserveResponse, setReserveResponse] = useState(null); // State for reserve response

  const fetchRoom = async () => {
    try {
      const authData = JSON.parse(Cookies.get('authenticationData'));
      const token = authData?.idToken; // Assuming the token is stored in authData.token
      const roomid = authData?.AssignedRoom?.replace("room",""); // Assuming the AssignedRoom is stored in authData.AssignedRoom

      if (!roomid) {
        setError("You have not reserved any room yet");
        setLoading(false);
        return;
      }

      const response = await axios.get(`${config.apiBaseUrl}/rooms/${roomid}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      console.log(response.data);
      setRoom(response.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setError(`${error.response?.status} - ${error.response?.data} - Failed to load room.`);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRoom();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div className="text-danger">{error}</div>;
  }

  // Fonction qui gère la réservation d'une chambre
  const handleCancelRoom = async () => {
    try {
      const authData = JSON.parse(Cookies.get('authenticationData'));
      const token = authData?.idToken; // Assuming the token is stored in authData.token
      const userId = authData?.UID; // Assuming the user ID is stored in authData.UserId

      const response = await axios.post(`${config.apiBaseUrl}/cancel`, { userId: userId }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      console.log('Reservation Cancelled successfully', response.data);
      setPopup({ visible: true, message: response.data.message || 'Reservation Cancelled successfully!' });
      setReserveResponse(response.data); // Set the reserve response data

      // Update the cookie to remove the assigned room
      const updatedAuthData = { ...authData, AssignedRoom: null };
      Cookies.set('authenticationData', JSON.stringify(updatedAuthData));

      // Refresh room data 2000ms after reservation
      setTimeout(fetchRoom, 2000);

    } catch (error) {
      console.error('Error cancelling your reservation', error, error.response?.data);
      const errorMessage = error.response?.data || 'Error cancelling your reservation. Please try again.';
      setPopup({ visible: true, message: errorMessage });
    }
  };

  const closePopup = () => {
    setPopup({ visible: false, message: "" });
  };

  return (
    <div className="container">
      <h1>Select Your Room</h1>
      <div className="grid">
        <div
          key={room.RoomID}
          className={`box ${selectedRoom === `room${room.RoomID}` ? "selected" : ""}`}
        >
          Room {room.RoomID}
          <br />
          Occupants: {room.NumberOfOccupants} / {room.Capacity}
        </div>
      </div>
      <div className="reserve-button-container">
        <button onClick={handleCancelRoom}>Cancel your reservation</button>
      </div>
      {popup.visible && (
        <div className="popup">
          <div className="popup-content">
            <p>{popup.message}</p>
            <button onClick={closePopup}>Close</button>
          </div>
        </div>
      )}
      {reserveResponse && (
        <div className="reserve-response">
          <h2>Cancel Response</h2>
          <pre>{JSON.stringify(reserveResponse, null, 2)}</pre>
        </div>
      )}
    </div>
  );
}

export default Reservation;